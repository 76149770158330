import axios from 'axios';
import connConfig from './services/connConfig'; 
import { 
  GeckoUser, 
  UserSession,
  DataResponse,
  GeckoValidLanguageType,
  IGeckoCompany, 
} from '@/types/userSessionTypes';
import AuthManagerServices from '@/services/authManagerService';
import { $t } from './services/localization/LocalizationApp';

// TODO: implement groups
const VERSION_STORAGE_KEY = 'version-2.1.0';
const DEFAULT_LANGUAGE = 'es_ES'; 
const DEFAULT_THEME = 'clear';
//TODO: move to types
export interface UserSiteReg {
    identifier: string
    name: string
    categoryName: string | undefined
    role: string
    location_latitude: number | null
    location_longitude: number | null
    lineNominalPower: number
    online: boolean,
    alarms: {
      numAlarms: number,
      severity: string
      list: Array<{
        alarmTag: string,
        render: {
          message: string
        }
      }>
    },
    siteImageUrl: string | null
}

export interface UserGroupReg {
  name: string;
  identifier: string;
  type: string;
}

/**
 * The `AuthManager` class handles authentication and user session management.
 * It provides methods to check if a user is logged in, retrieve session information,
 * user information, site and group lists, and perform login and logout operations.
 */
export default class AuthManager {
  private static _instance: AuthManager;
  private _user: GeckoUser;
  private _session: UserSession;
  private _expirationDate: Date;
  private _version: string ;
  
  private constructor(){
    const user = AuthManager.getBrowserUser();
    const session = AuthManager.getBrowserSession();
    const version = AuthManager.getBrowserVersion();
    this._user = user;
    this._session = session;
    this._expirationDate = new Date(session.expiration);
    this._version = version;
  }

  private static get instance(){
    if(!AuthManager._instance){
      AuthManager._instance = new AuthManager();
    } 
    return AuthManager._instance;
  }

  /**
   * Checks if the user is currently logged in.
   * @returns {boolean} Returns true if the user is logged in, false otherwise.
   */
  static isLoggedIn(): boolean {
    const session = AuthManager.instance._session;
    if(!session?.token) {
      // session info not existant
      return false;
    }

    const expirationDate = new Date(session.expiration);
    if(expirationDate < new Date()){
      // clean session info if existant but expired
      AuthManager.clearLocalData();
      AuthManager.clearBrowserData();
      return false;
    }
    return true;
  }

  /**
   * retrieves the token for the current session, it is used by the axios interceptor to add the token to the request
   */
  static getSession(): UserSession {
    return AuthManager.instance._session;
  }

  static getUser(): GeckoUser {
    return AuthManager.instance._user;
  }

  /**
   * Chek if current user is a company member
   * @returns 
   */
  static isUserCompanyMember(): boolean {
    const user = AuthManager.getUser()
    return user.company?.id !== null && user.company?.id !== undefined
  }

  /**
   * Check if user is company admin
   * @returns 
   */
  static isCompanyAdmin(): boolean {
    const user = AuthManager.getUser()
    return ( 
      (user.company?.id !== null && user.company?.id !== undefined) 
      && (user.systemRole === 'company_admin')
    )
  }

  /**
   * Check if user is admin
   * @returns 
   */
  static isAdmin(): boolean {
    const user = AuthManager.getUser()
    return user.systemRole === 'admin'
  }

  static getVersion(): string {
    return AuthManager.instance._version;
  }

  /**
   * Check if we must show the extra features menu items
   * @returns 
   */
  static showExtraFeaturesMenuItems(): boolean {
    const user = AuthManager.getUser()
    const companyName = user?.company?.name || ''
        
    return !companyName.toLowerCase().includes("riello")
     && user.email !=='info@infitech.es'
  }

  static showCompanyMenuItems(): boolean {
    const user = AuthManager.getUser()
    return (user?.company?.id !== undefined && user?.company?.id !== null)
  }

  private static validateVersion(): boolean {
    return AuthManager.getBrowserVersion() === VERSION_STORAGE_KEY;
  }

  /**
   * syncs the local store with the browser storage bypàssing login, 
   * do not use this method directly, use the facade in the account store instead
   */
  public static syncBrowserData(): void {
    if(!AuthManager.validateVersion()){
      // Clear local data
      AuthManager.clearLocalData();
      // Clear browser data
      AuthManager.clearBrowserData();
    }
    
    const version = AuthManager.getBrowserVersion();
    const user = AuthManager.getBrowserUser();
    const session = AuthManager.getBrowserSession();
    AuthManager.instance._user = user;
    AuthManager.instance._session = session;
    AuthManager.instance._expirationDate = new Date(session.expiration);
    AuthManager.instance._version = version;
  }

  private static getBrowserSession() : UserSession{
    const sessionStr = localStorage.getItem("geckoSession")||'{}';
    const sessData = JSON.parse(sessionStr) as Partial<UserSession>;
    return {
      maps_provider_api_token: sessData.maps_provider_api_token||'1',
      expiration: sessData.expiration||'0',
      issued: sessData.issued||'0',
      token: sessData.token||'',
      renewals: sessData.renewals||0,
    }
  }
  private static setBrowserSession (session: UserSession): void {
    localStorage.setItem("geckoSession", JSON.stringify(session));

  }
  private static getBrowserUser() : GeckoUser {
    const version = AuthManager.getBrowserVersion();
    if(version !== VERSION_STORAGE_KEY){
      return {
        id: '',
        email: '',
        name: '',
        avatarUrl: '',
        themeName: DEFAULT_THEME,
        language: DEFAULT_LANGUAGE,
        lastname: '',
        systemRole: 'user',
        company: {} as IGeckoCompany,
      }
    }
    const userStr = localStorage.getItem("user")||"{}";
    const userObj: Partial<GeckoUser> = JSON.parse(userStr);
    return{
      id: userObj.id||'',
      email: userObj.email||'',
      name: userObj.name||'',
      avatarUrl: userObj.avatarUrl,
      themeName: userObj.themeName||DEFAULT_THEME,
      language: userObj.language||DEFAULT_LANGUAGE,
      lastname: userObj.lastname||'',
      systemRole: userObj.systemRole||'user',
      company: userObj.company||{} as IGeckoCompany,
    }
  }

  private static setBrowserUser (user: GeckoUser) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  
  private static getBrowserVersion (): string {
    try{
      const version = localStorage.getItem("version")||'';
      return JSON.parse(version) ;
    }catch(err: any){
      localStorage.setItem("version", JSON.stringify(''));
      return '';
    }
  }
  private static setBrowserVersion (version: string): void {
    localStorage.setItem("version", JSON.stringify(version));
  }


  private static clearBrowserData (): void {
    localStorage.removeItem("user");
    localStorage.removeItem("geckoSession");
    localStorage.removeItem("version");
  }

  private static clearLocalData (): void {
    AuthManager.instance._session.expiration = '0';
    AuthManager.instance._session.token = '';
    AuthManager.instance._session.maps_provider_api_token = '';
    AuthManager.instance._version = '';
    AuthManager.instance._expirationDate = new Date(0);
    AuthManager.instance._user = {
      id: '',
      name: '',
      lastname: '',
      email: '',
      language: DEFAULT_LANGUAGE,
      themeName: DEFAULT_THEME,
      systemRole: 'user',
      company: {} as IGeckoCompany,
    };
  } 

  /**
   * logot the user from local storage and server side
   */
  static async logOut(): Promise<void> {
    // Clean server session
    try {
      await AuthManagerServices.serverLogout(AuthManager.instance._session.token);
    }catch(err: any){
      console.warn($t('error_logout_session'), AuthManager.instance._user.email, err.message);
    }
    // Clear local data
    AuthManager.clearLocalData();

    // Clear browser data
    AuthManager.clearBrowserData();


  }


  /**
   * logot the user from local storage and store side, does not clean the server session
   */
  static async logOutSession(): Promise<void> {
    // Clear local data
    AuthManager.clearLocalData();

    // Clear browser data
    AuthManager.clearBrowserData();

  }


  static async refreshSession(): Promise<DataResponse<void>> {
    // call server refresh token endpoint
    // refresh token locally
    // refresh token in browser
    throw new Error('Not implemented');
  }


  /**
   * Login a new user into ther system, triggers a server request and sync the browser storage with the server
   * if successfull, if fails cleans any user data from the browser and throws an error.
   * do not use this method directly, use the facade in the account store instead
   */
  static async logIn(email: string, password: string): Promise<DataResponse<void>> {
    try {
      // Send server request
      const loginResponse = await AuthManagerServices.serverLogin(email, password);
      if (!loginResponse?.token) {
        throw new Error(loginResponse.message);
      }
      
      AuthManager.storeLoginResponse(loginResponse);

      return {
        isOk: true,
      };
      
    }
    catch(err: any) {
      console.error('error logging in user: ', email, err.message);
      if(err.response?.status === 401){
        return {
          isOk: false,
          message: $t("email_password_incorrect"),
          errorCode: "unauthenticated",
        };
      }
      return {
        isOk: false,
        message: $t("error_login") + err.message
      };
    }
  }

  /**
   * Store a login DTO in the local and browser storage
   */
  static storeLoginResponse(loginResponse: any): void {

    const userObj: GeckoUser = {
      id: loginResponse.user.id,
      name: loginResponse.user.name,
      lastname: loginResponse.user.lastname,
      email: loginResponse.user.email,
      language: loginResponse.user.default_language as GeckoValidLanguageType,
      themeName: DEFAULT_THEME,
      systemRole: loginResponse.user.system_role,
      company: loginResponse.user.company,
    }
    const sessionObj: UserSession = {
      maps_provider_api_token: loginResponse.maps_provider_api_token,
      token: loginResponse.token,
      renewals: 80,
      issued: new Date().toISOString(),
      expiration: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 2  ).toISOString(), // 2 dias
    }

    // store data locally
    AuthManager.instance._user = userObj;
    AuthManager.instance._session = sessionObj;
    AuthManager.instance._expirationDate = new Date(AuthManager.instance._session.expiration);

    // store data in browser
    AuthManager.setBrowserUser(AuthManager.instance._user);
    AuthManager.setBrowserSession(AuthManager.instance._session);
    AuthManager.setBrowserVersion(VERSION_STORAGE_KEY);
  }

  static async resetPassword(email: string ): Promise<DataResponse<void>> {
    try {
      // Send request
      console.log('reset password',email);

      return { 
        isOk: true, 
        message: $t("account_password_reset_email_sent")
      };
    }
    catch(err: any) {
      console.error('error resetting password for user: ', email, err.message);
      return {
        isOk: false,
        message: $t("error_reset_password")
      };
    }
  }

  static async changePassword(data: {
    accountId?: string,
    recoveryCode?: string,
    oldPassword?: string,
    newPassword: string,
  }): Promise<DataResponse<void>> {
    try {
      // Send request
      console.log('change password',data.accountId, data.recoveryCode);
      return {
        isOk: true,
        message: $t("password_changed_successfully")
      }
    }
    catch(err: any) {
      console.error('error changing password for user: ', data.accountId, err.message);
      return {
        isOk: false,
        message: $t("error_changing_password")
      };
    }
  }

  static async changePasswordUser(newPassword: string) : Promise<DataResponse<void>>{
    try{
      await AuthManagerServices.serverChangePassword(newPassword);
      return{
        isOk: true,
        message: $t('password_changed_successfully')
      }
    }catch(err: any){
      console.error($t('error_changing_password'), err.message)
      if(err.response?.status === 401){
        return {
          isOk: false,
          message: $t("email_password_incorrect"),
          errorCode: "unauthenticated",
        };
      }
      if(err.response?.status === 400){
        return{
          isOk: false,
          message: $t("invalid_password"),
          errorCode: 'invalid_argument',
        }
      }
      return {
        isOk: false,
        message: $t("error_failed_changing_password"),
      }
    }
  }

  static async createAccount(email:string, password: string): Promise<DataResponse<void>> {
    try {
      // Send request
      console.log('create account',email, password);

      return {
        isOk: true,
        message: $t("account_created_successfully")
      };
    }
    catch (err: any) {
      console.error('error creating account for user: ', email, err.message);
      return {
        isOk: false,
        message: $t("error_failed_create_account")
      };
    }
  }

}

