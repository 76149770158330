<template>
  <div
    class="dx-swatch-additional side-navigation-menu"
    @click="forwardClick"
  >
    <slot />
    <div class="menu-container">
      <div class="changeMode">
        <DxButton
          styling-mode="text"
          :id="logout"
          @click="logoutFunc"
        >
        <img 
          :src="require('@/assets/icons/logout.svg')" 
          height="18" 
          width="18"           
          @mouseenter="togglePopover" 
          @mouseleave="togglePopover"
        />
        </DxButton> 
      </div>
      <dx-tree-view
        ref="treeViewRef"
        :items="treeViewItems"
        key-expr="path"
        selection-mode="single"
        :focus-state-enabled="false"
        expand-event="click"
        @item-click="handleItemClick"
        width="100%"
        :itemTemplate="itemTemplate"
      />
    </div>
  </div>
  <DxPopover
    :width="49"
    :height="40"
    :visible="popupVisible"
    target="#logout"
    position="top"
  >
    <strong>{{ $t('menu_logout') }}</strong>
  </DxPopover>

</template>

<script setup lang="ts">
import { onMounted, ref, watch, defineProps, defineEmits } from 'vue';
import { useRoute, useRouter } from 'vue-router'; 
import { ItemClickEvent } from 'devextreme/ui/tree_view';
import { DxPopover } from 'devextreme-vue/popover';
import DxTreeView from 'devextreme-vue/tree-view';
import { DxButton } from "devextreme-vue";
import { getSizes } from '@/utils/media-query';
import { navigationTreeSeed, INavItem } from '@/app-navigation';
import { useAccountStore } from "@/stores/account";
import AuthManager from '@/AuthManager';
import { $t } from '@/services/localization/LocalizationApp';
import { GeckoUser } from '@/types/userSessionTypes';

const props = defineProps({
  compactMode: Boolean
});

const emit = defineEmits(['click']);
const route = useRoute();
const router = useRouter();
const logout = "logout";
const popupVisible = ref(false);
const accountStore = useAccountStore();
const treeViewItems = ref([]);

function togglePopover(): void {
  popupVisible.value = !popupVisible.value;
}

async function logoutFunc(): Promise<void> {
  await accountStore.logOut();
  router.push({
    path: "/login-form",
  });
}

const itemTemplate = (itemData: INavItem) => {
  return `
  <div title="${itemData.title}" data-src="${itemData.path}">
    <i class="dx-icon dx-icon-${itemData.icon}"></i>
    <span>${$t(itemData.text)}</span>
    </div>
  `;
};
  
const processNavItemMenu = (item: any, user:GeckoUser, showExtraFeaturesMenuItems:boolean, showCompanyMenuItems: boolean) => {
  normalizeMenuItemPath(item)

  item.expanded = getSizes()['screen-large'];
  item.title = item.text;

  item.visible = true

  // Show premium features
  if ((item.premiumFeature || false) && !showExtraFeaturesMenuItems) {
    item.visible = false;
  }

  // Show company menu items
  if ((item.companyMenuItem || false) && !showCompanyMenuItems) {
    item.visible = false;
  }

  return item;
}


function getTreeviewMenuItems(): Array<any> {
  const showExtraFeaturesMenuItems = AuthManager.showExtraFeaturesMenuItems()
  const showCompanyMenuItems = AuthManager.showCompanyMenuItems()
  const user = AuthManager.getUser()

  const items = navigationTreeSeed.map((item: any) => {
    item = processNavItemMenu(
      item, 
      user,
      showExtraFeaturesMenuItems, 
      showCompanyMenuItems
    )
    if (item.items) {
      item.items = item.items.map(processNavItemMenu) 
    }

    return item
  })

  return items
}

function normalizeMenuItemPath(menuItem: INavItem): void {
  // If menu item has path and not starts with an '/', prefix with /
  if (menuItem.path && !(/^\//.test(menuItem.path))) {
    menuItem.path = `/${menuItem.path}`;
  }
}

/**
 * Process the path of a menu item to convert template literals in the string to values
 * @param path of a menu item 
 * @param templateVariables object where each property is a template literal to find and replace in the path 
 * @returns the parsed path 
 */
function processTemplateLiteralsInPath(path: string, templateVariables: object): string {
  for (const [key, value] of Object.entries(templateVariables)) {
    path = path.replace(new RegExp(`\\$\\{${key}\\}`, 'g'), value);
  }

  return path
}

const treeViewRef = ref<DxTreeView|null>(null);

function forwardClick (...args: any[]) {
  emit("click", args);
}

/** 
 * Handler menu item click
 * Navigate to the route of the menu item 
 */
function handleItemClick(e: ItemClickEvent) {
  if (!e.itemData?.path || props.compactMode) {
    return;
  }

  // Replace variables in the path by their values
  const user = AuthManager.getUser()
  const path = processTemplateLiteralsInPath(e.itemData.path, {
      userId: user.id,
      companyId: user?.company?.id
    }
  )

  router.push(path);

  const pointerEvent = e.event;
  pointerEvent?.stopPropagation();
}

function updateSelection(): void {
  if (!treeViewRef.value || !treeViewRef.value.instance) {
    return;
  }

  treeViewItems.value = getTreeviewMenuItems() as never[];
  treeViewRef.value?.instance?.getDataSource()?.load()
  treeViewRef.value.instance.selectItem(route.path);
  treeViewRef.value.instance.expandItem(route.path);
}

onMounted(() => {
  updateSelection();
  if (props.compactMode) {
    treeViewRef.value?.instance?.collapseAll?.();
  }
});
watch(
  () => route.path,
  () => {
    updateSelection();
  }
);
watch(
  () => props.compactMode,
  () => {
    if (props.compactMode) {
      treeViewRef.value?.instance?.collapseAll();
    } else {
      updateSelection();
    }
  }
);

</script>

<style lang="scss">
@import "@/dx-styles.scss";
@import "@/themes/generated/variables.additional.scss";
.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node[aria-level="2"] .dx-treeview-item-content{
  padding: 0  !important;

}

.side-navigation-menu {
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    display: flex;
    height: 100%;
    align-items: stretch; /* align trees to fill height */
    justify-content: space-between; /* max space between */
    flex: 1;
    flex-direction: column-reverse;


    .changeMode{
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding: 9px;
    }
    .dx-switch-container{
      // height: 20px;
      border-radius: 50px;
      overflow: hidden;
      border: 1px solid;

      .dx-switch-handle::before{
        height: 18px;
        border-radius: 60px;
      }
}
    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
      }
      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: 500;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $base-accent;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: lighten($base-bg, 4);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
  }
}

</style>