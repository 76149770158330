import { defineStore } from 'pinia';
import AuthManager, { UserSiteReg, UserGroupReg } from '@/AuthManager';
import {
  DataResponse,
  GeckoValidThemeType,
  GeckoValidLanguageType,
  IGeckoCompany,
} from '@/types/userSessionTypes';
import { LocalizationApp } from '@/services/localization/LocalizationApp';
import { UmamiWebTracker } from '@/utils/initUmamiWebTrackerPlugin';

interface IAccountStore {
  language: GeckoValidLanguageType;
  theme: GeckoValidThemeType;
  user:{
    id: string|undefined;
    name: string|undefined;
    email: string|undefined;
    lastname: string|undefined;
    company: IGeckoCompany  | undefined;
  };
  session:{
    token: string|undefined;
    expiration: Date;
    maps_provider_api_token:string|undefined;
  }
}

const DEFAULT_LANGUAGE: GeckoValidLanguageType = 'es_ES';
const DEFAULT_THEME: GeckoValidThemeType = 'clear';

export const useAccountStore = defineStore('account', {
  state: () => ({
    language: DEFAULT_LANGUAGE, // override with browser language
    theme: DEFAULT_THEME,
    user: {
      id: undefined,
      name: undefined,
      email: undefined,
      lastname: undefined,
      company: undefined,
    },
    session: {
      token: undefined,
      expiration: new Date(0),
      maps_provider_api_token: undefined,
    },
  } as IAccountStore),

  actions: {

    /**
     * Sync browser data with stores, do not use this method to check if session is active
     */
    syncBrowserData(): void {
      const user = AuthManager.getUser();
      const session = AuthManager.getSession();
      this.$state.user = {
        id: user.id,
        name: user.name,
        email: user.email,
        lastname: user.lastname,
        company: user.company,
      }
      this.$state.language = user.language;
      this.$state.theme = user.themeName;
      this.$state.session = {
        token: session.token,
        expiration: new Date(session.expiration),
        maps_provider_api_token: session.maps_provider_api_token,
      };
    },

    async logIn (email: string, password: string):Promise<DataResponse<void>>{
      // send login request to auth service
      const loginRes = await AuthManager.logIn(email, password);

      // if login was successful, sync browser data
      if(loginRes.isOk) {
        this.syncBrowserData()

        // Set user to the web tracker
        UmamiWebTracker.setUser(email);

        //We must set the user's language after been logged in
        await LocalizationApp.init()
      }
      return loginRes;
    },

    /**
     * used to detect if session is expired or alive in the local store + plinia store
     */
    isSessionActive(): boolean {
      const now = new Date();

      // session is active in store and valid
      if(this.$state.session.token && this.$state.session.expiration > now)
        return true;

      // session inexistant in store but probably valid in browser
      AuthManager.syncBrowserData();
      if(AuthManager.isLoggedIn()) this.syncBrowserData();
      if(this.$state.session.expiration > now)
        return true;

      // session is invalid in browser and inexistant in store
      return false;
    },

    async logOut(): Promise<void> {
      this.$state.user = {
        id: undefined,
        name: undefined,
        email: undefined,
        lastname: undefined,
        company: undefined,
      };
      this.$state.session = {
        token: undefined,
        expiration: new Date(0),
        maps_provider_api_token: undefined,
      };
      this.$state.language = DEFAULT_LANGUAGE;
      this.$state.theme = DEFAULT_THEME;
      return AuthManager.logOut();
    },

    async logOutSession(): Promise<void> {
      this.$state.user = {
        id: undefined,
        name: undefined,
        email: undefined,
        lastname: undefined,
        company: undefined,
      };
      this.$state.session = {
        token: undefined,
        expiration: new Date(0),
        maps_provider_api_token: undefined,
      };
      this.$state.language = DEFAULT_LANGUAGE;
      this.$state.theme = DEFAULT_THEME;
      return AuthManager.logOutSession();
    },

    /**
     * facade for AuthService.resetPassword
     */
    async resetPassword(email: string ): Promise<DataResponse<void>> {
      return AuthManager.resetPassword(email);
    },
    /**
     * Facade for AuthService.changePassword
     */
    async changePassword(data: {
      accountId?: string,
      recoveryCode?: string,
      oldPassword?: string,
      newPassword: string,
    }): Promise<DataResponse<void>> {
      return AuthManager.changePassword(data);
    },

    async changePasswordUser( newPassword: string): Promise<DataResponse<void>> {
      return AuthManager.changePasswordUser(newPassword);
    },

    /**
     * Facade for AuthService.createAccount
     */
    async createAccount(email:string, password: string): Promise<DataResponse<void>>{
      return AuthManager.createAccount(email, password);
    },

  }
});