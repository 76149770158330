import "bootstrap/dist/css/bootstrap.min.css";
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import { createPinia } from 'pinia';
import themes from "devextreme/ui/themes";
import errorHandlingMixin from "./errors/errorHandlingMixin"
import App from "./App.vue";
import appInfo from "./app-info";
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import { LocalizationApp } from "./services/localization/LocalizationApp";
import { UmamiWebTracker } from './utils/initUmamiWebTrackerPlugin';


config({ licenseKey });

//Test to check teh local in the route
// router.beforeEach((to, from, next) => {
//   const localeParam = to.params.locale
//   console.log('Locale in the  route:', localeParam)
//   next();
// });

themes.initialized(async () => {
  const app = createApp(App);
  const pinia = createPinia();
  app.use(pinia);
  app.use(router);
  app.mixin(errorHandlingMixin); // Add the mixin to the app
  app.config.globalProperties.$appInfo = appInfo;

  await LocalizationApp.init()

  // const umamiPlugin = UmamiWebTracker.init(router);
  // app.use(umamiPlugin);

  app.mount('#app');
});
