import { VueUmamiPlugin, identifyUmamiSession } from '@jaseeey/vue-umami-plugin';

/**
 * Initialize the Umami web tracker plugin
 * We must use a plugin, and not directly the umami script, to be able
 * to track the route changes in the SPA application
 */
export class UmamiWebTracker {

  /**
   * Init the web tracker
   * @param router - The router instance
   * @returns The umami plugin instance
   */
  static init(router: any): any {
    const umamiPlugin = VueUmamiPlugin({
      websiteID: 'a1f1bcdd-a07a-488e-a1b5-cd4fa80cfccf', //Identifier of the gecko cloud website in umami
      scriptSrc: 'https://cloud.umami.is/script.js',
      router,
      allowLocalhost: true,
    });

    return umamiPlugin;
  }

  /**
   * Set the user email in the umami session
   * This allow to track the logged user in the umami dashboard
   * @param email - The user email
   *
   */
  static setUser(email: string):void {
    identifyUmamiSession({
      email,
    });
  }
}


