
import axios from "axios";
import AuthManager from '@/AuthManager';
import connConfig from './connConfig';
import { errorMapper } from '@/errors/errorMapper';
import { UserSiteReg, UserGroupReg } from '@/AuthManager';

export interface LoginResponse {
  token: string;
  maps_provider_api_token: string;
  user: {
    id: string;
    name: string;
    lastname: string;
    email: string;
    default_language: string;
    system_role: string;
    company:{
      id: string;
      name: string;
      companySettings: {
        appearance: {
          logoUrl: string; // url
          appTitle: string;
          reportLogoUrl: string; // url
        }
      }
    }
  } ;
}

/**
 * make a server request to login the user
 */
async function serverLogin(email: string, password: string): Promise<any> {
  try {
    const token = AuthManager.getSession()?.token;
    const config = connConfig.connData(null, token);
    const response = await axios.post(`${config.baseURL}/directory/api/v1/auth/login`, {
      email,
      password,
    },
    { headers: config.headers }
  );
  if(response.status !== 200) throw new Error('Error logging in');
    return response.data;
  } catch (error) {
    return errorMapper(error);
  }
}

/**
 * kills the session in the server
 */
async function serverLogout(sessionId: string): Promise<any> {
  try{
    const config = connConfig.connData(null, sessionId);
    const response = await axios.post(
      `${config.baseURL}/directory/api/v1/auth/logout`, 
      { 
        sessionId, 
      },
      { headers: config.headers }
    );
    // Response 401 when session was not foung. Backend restart, session expired, etc. Not considered an error for a logout action
    if (response.status !== 200 && response.status !== 401) {
      throw new Error('Logout failed');
    }
  }catch(error){
    return errorMapper(error);
  }
}

async function serverChangePassword (newPassword: string) : Promise<void> {
  try{
    const token = AuthManager.getSession().token;
    const config = connConfig.connData(null, token);
    const response = await axios.post(`${config.baseURL}/directory/api/v1/auth/change-password`, 
      { password: newPassword },
      { headers: config.headers }
    )
    if (response.status !== 200) {
      throw new Error('Failed to change password');
    }
  }catch(err: any){
    console.error('Error in change password',err.response);
    return;
  }
}





export default {
  serverLogin,
  serverLogout,
  serverChangePassword,
}